/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCall, Loader, useDidUpdate } from "@epcnetwork/core-ui-kit";

import { getMyProfile } from "api";
import { RootState, setUser } from "store";
import { Route } from "components";
import { routes } from "config/routes.config";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(true);

  const { submit, onCallSuccess, onCallError } = useCall(getMyProfile);

  useDidUpdate(
    () => {
      if (token && !user) {
        setIsLoading(true);
        submit();
      } else {
        setIsLoading(false);
      }
    },
    [token, user],
    true,
  );

  onCallSuccess(async (payload) => {
    dispatch(setUser(payload));
    setIsLoading(false);
  });

  onCallError(() => {
    setIsLoading(false);
  });

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          inset: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader type="puff-loader" size={100} />;
      </div>
    );
  }

  return (
    <Switch>
      {routes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
    </Switch>
  );
};

export default App;
