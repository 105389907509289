import React from "react";
import { useHistory } from "react-router";

import {
  Button,
  Container,
  FetchingError,
  Loader,
  NoContent,
  Table,
} from "@epcnetwork/core-ui-kit";
import { entityName, tableColumns } from "./connections-list.constants";
import { useConnectionsListHook } from "./connections-list.hook";
import TableRow from "./table-row/table-row";

import styles from "./connections-list.module.css";
import { CONNECTIONS_ADD_PAGE } from "constants/routes.constants";

const ConnectionsListPage: React.FC = () => {
  const history = useHistory();
  const { payload, error, refresh, pagination, showLoader, showNoContent, showError, showContent } =
    useConnectionsListHook();

  const errorMessage = error?.message || "";

  const handleAddNew = () => {
    history.push(CONNECTIONS_ADD_PAGE.path);
  };

  return (
    <Container className={styles.container}>
      <div className={styles.toolbar}>
        <Button onClick={handleAddNew}>Add New</Button>
      </div>
      {showLoader && <Loader />}
      {showNoContent && <NoContent title="List is empty" subtitle="There are no connections" />}
      {showError && <FetchingError title="Fetching error" subtitle={errorMessage} />}
      {showContent && (
        <Table
          className={styles.table}
          entityName={entityName}
          columns={tableColumns}
          list={payload?.data || []}
          error={errorMessage}
          loading={showLoader}
          refresh={refresh}
          multiSelect
          pagination={pagination}
          row={(item) => <TableRow key={item.id} item={item} refresh={refresh} />}
        />
      )}
    </Container>
  );
};

export default ConnectionsListPage;
