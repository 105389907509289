import { List } from "@epcnetwork/core-ui-kit";

import { ConnectionsModel } from "models";
import { apiMiddleware } from "../api.middleware";
import { PostConnectionsData } from "./connections.types";

export const getConnection = apiMiddleware<ConnectionsModel>()({
  endpoint: "/connections/:connectionId",
  method: "get",
});

export const getConnections = apiMiddleware<List<ConnectionsModel>>()({
  endpoint: "/connections",
  method: "get",
});

export const postConnection = apiMiddleware<ConnectionsModel, PostConnectionsData>()({
  method: "post",
  endpoint: "/connections",
});

export const putConnection = apiMiddleware<ConnectionsModel, PostConnectionsData>()({
  method: "put",
  endpoint: `/connections/:connectionId`,
});

export const deleteConnection = apiMiddleware()({
  method: "delete",
  endpoint: `/connections/:connectionId`,
});

export const get = apiMiddleware<ConnectionsModel>()({
  endpoint: "/connections/:connectionId",
  method: "get",
});
