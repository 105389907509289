import React from "react";
import { object, SchemaOf, string, number } from "yup";
import { FormField } from "@epcnetwork/core-ui-kit";

export const maropostSchema: SchemaOf<MaropostType> = object({
  account_id: number().min(1, "This field is required").required("This field is required"),
  api_key: string().required("This field is required"),
});

export type MaropostType = {
  account_id: number;
  api_key: string;
};
const Maropost: React.FC = () => {
  return (
    <>
      <FormField
        type="number"
        name={`connector.account_id`}
        label="Account Id"
        placeholder="Account Id"
      />
      <FormField type="text" name={`connector.api_key`} label="Api Key" placeholder="Api Key" />
    </>
  );
};

export default Maropost;
