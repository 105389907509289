import React from "react";
import { object, SchemaOf, string } from "yup";
import { FormField } from "@epcnetwork/core-ui-kit";

export const exactTargetSchema: SchemaOf<ExactTargetType> = object({
  auth_origin: string().required("This field is required"),
  client_id: string().required("This field is required"),
  client_secret: string().required("This field is required"),
  origin: string().required("This field is required"),
  soap_origin: string().required("This field is required"),
});

export type ExactTargetType = {
  auth_origin: string;
  client_id: string;
  client_secret: string;
  origin: string;
  soap_origin: string;
};

const ExactTarget: React.FC = () => {
  return (
    <>
      <FormField
        type="text"
        name={`connector.auth_origin`}
        label="Auth origin"
        placeholder="Auth origin"
      />
      <FormField
        type="text"
        name={`connector.client_id`}
        label="Client Id"
        placeholder="Client Id"
      />
      <FormField
        type="text"
        name={`connector.client_secret`}
        label="Client Secret"
        placeholder="Client Secret"
      />
      <FormField type="text" name={`connector.origin`} label="Origin" placeholder="Origin" />
      <FormField
        type="text"
        name={`connector.soap_origin`}
        label="Soap Origin"
        placeholder="Soap Origin"
      />
    </>
  );
};

export default ExactTarget;
