const appEnv = process.env.REACT_APP_ENV || "development";

interface Environment {
  apiUrl: string;
}

interface Environments {
  [key: string]: Environment;
}

export const environments: Environments = {
  development: {
    apiUrl: process.env.REACT_APP_API || "http://localhost:3000/",
  },
  test: {
    apiUrl: process.env.REACT_APP_API || "https://api.in-dev.esphub.io/api",
  },
  staging: {
    apiUrl: process.env.REACT_APP_API || "https://api.in-dev.esphub.io/api",
  },
  production: {
    apiUrl: process.env.REACT_APP_API as string,
  },
};

export const appEnvironment: Environment = environments[appEnv];
