import React from "react";
import { Route as RouterRoute } from "react-router-dom";

import { RouteConfig } from "config/routes.config";
import { AuthRoute } from "../auth-route/auth-route";
import Navigation from "components/layout/navigation/navigation";

export const Route: React.FC<RouteConfig> = ({
  component,
  showNavigation,
  ...route
}: RouteConfig) => {
  const RouteComponent = route.auth ? AuthRoute : RouterRoute;

  return (
    <RouteComponent
      {...route}
      render={(props: unknown) => (
        <Navigation component={component} showNavigation={showNavigation} {...props} />
      )}
    />
  );
};
