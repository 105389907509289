import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { notification, useFormValues, useSubmit } from "@epcnetwork/core-ui-kit";

import { postConnection, putConnection, getConnection } from "api";
import { CONNECTIONS_LIST_PAGE } from "constants/routes.constants";
import { ConnectionsFormParams, InitialValues } from "./connections-form.types";
import { initialValues } from "./connections-form.constants";

export const useConnectionsFormHook = () => {
  const history = useHistory();

  const { connectionId } = useParams<ConnectionsFormParams>();

  const [error, setError] = useState("");

  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postConnection,
    putConnection.setParams({ connectionId }),
    !!connectionId,
  );

  const onSubmit = onSubmitMapping((data: InitialValues) => {
    return {
      name: data.name,
      api_type: data.api_type,
      [`${data.api_type}_data`]: data.connector,
    };
  });

  onSubmitSuccess(() => {
    const title = connectionId ? "Connection updated" : "Connection created";
    const message = connectionId
      ? "Successfully updated connection"
      : "Successfully created connection";
    notification.success(title, message);
    history.push(CONNECTIONS_LIST_PAGE.path);
  });

  onSubmitError((error) => {
    setError(error.message);
  });

  const { formProps, mapInitialValues } = useFormValues(
    initialValues,
    getConnection.setParams({ connectionId }),
    !!connectionId,
  );

  mapInitialValues((connection) => ({
    name: connection.name,
    api_type: connection.api_type,
    connector: connection[`${connection.api_type}_data`] || { api_key: "" },
  }));

  const handleCancel = () => {
    history.push(CONNECTIONS_LIST_PAGE.path);
  };

  return { formProps, connectionId, error, onSubmit, handleCancel };
};
