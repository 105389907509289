import React from "react";

import {
  LOGIN_PAGE,
  LOGOUT_PAGE,
  NOT_FOUND_PAGE,
  DEFAULT_PAGE,
  CONNECTIONS_LIST_PAGE,
  CONNECTIONS_ADD_PAGE,
  CONNECTIONS_EDIT_PAGE,
} from "constants/routes.constants";

import NotFound from "pages/404";
import Login from "pages/auth/login";
import Logout from "pages/auth/logout";
import ConnectionsListPage from "pages/connections/list";
import ConnectionsFormPage from "pages/connections/form";

export interface RouteConfig {
  path: string;
  component: React.FC<unknown>;
  name: string;
  exact: boolean;
  auth: boolean;
  showNavigation: boolean;
}

export const routes: RouteConfig[] = [
  { ...DEFAULT_PAGE, component: ConnectionsListPage },
  { ...CONNECTIONS_LIST_PAGE, component: ConnectionsListPage },
  { ...CONNECTIONS_ADD_PAGE, component: ConnectionsFormPage },
  { ...CONNECTIONS_EDIT_PAGE, component: ConnectionsFormPage },
  { ...LOGIN_PAGE, component: Login },
  { ...LOGOUT_PAGE, component: Logout },
  { ...NOT_FOUND_PAGE, component: NotFound },
];
