import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  TableRow,
  TableCell,
  formatDate,
  MenuButton,
  Menu,
  useCall,
  notification,
  TextEllipsis,
} from "@epcnetwork/core-ui-kit";

import { CONNECTIONS_EDIT_PAGE } from "constants/routes.constants";
import { ConnectionsModel } from "models";
import { deleteConnection } from "api";
import { getLinkPath } from "utils";

import styles from "./table-row.module.css";

type Props = {
  item: ConnectionsModel;
  refresh: VoidFunction;
};

const Row: React.FC<Props> = ({ item, refresh }: Props) => {
  const history = useHistory();
  const [isDeleted, setDeleted] = useState(false);

  const { submit, onCallSuccess, onCallError, submitting } = useCall(
    deleteConnection.setParams({ connectionId: item.id }),
  );

  onCallSuccess(() => {
    notification.success("Success", "Connection removed");
    refresh();
  });

  onCallError((error) => {
    setDeleted(false);
    notification.error("Error", error?.message || "Cannot remove connection");
  });

  const handleEdit = () => {
    history.push(getLinkPath(CONNECTIONS_EDIT_PAGE.path, { connectionId: item.id }));
  };

  const handleDelete = () => {
    setDeleted(true);
    submit();
  };

  if (isDeleted) {
    return null;
  }

  return (
    <TableRow id={item.id}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {item.name}
        </TextEllipsis>
      </TableCell>
      <TableCell>{item.api_type}</TableCell>
      <TableCell>{item.status}</TableCell>
      <TableCell className={styles.date}>{formatDate(item.created_at, "MMM dd, yyyy")}</TableCell>
      <TableCell>
        <Menu>
          <MenuButton onClick={handleEdit} disabled={submitting}>
            Edit
          </MenuButton>
          <MenuButton appearance="delete" onClick={handleDelete} disabled={submitting || true}>
            Delete
          </MenuButton>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default Row;
