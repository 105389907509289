import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { NotificationProvider, ThemeProvider } from "@epcnetwork/core-ui-kit";

import store from "store";
import { lightTheme } from "constants/themes.constants";

type Props = {
  children: React.ReactNode;
};

const themes = [lightTheme];

export const Providers: React.FC<Props> = ({ children }: Props) => (
  <Provider store={store}>
    <BrowserRouter>
      <NotificationProvider>
        <ThemeProvider themes={themes} initialTheme={themes[0]}>
          {children}
        </ThemeProvider>
      </NotificationProvider>
    </BrowserRouter>
  </Provider>
);
