import React, { memo, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, Tooltip } from "@epcnetwork/core-ui-kit";

import Menu from "./components/menu";

import styles from "./navbar.module.css";
import userImg from "assets/images/user.svg";
import openedImg from "assets/images/navigation-menu-opened.svg";
import closedImg from "assets/images/navigation-menu-closed.svg";
import { NavigationContext } from "../navigation/navigation";

type Props = {
  toggleSidebar: () => void;
  isSidebarOpen: boolean;
};

export const Navbar = memo(({ toggleSidebar, isSidebarOpen }: Props) => {
  const history = useHistory();

  const context = useContext(NavigationContext);
  const { activeRoute } = context || {};
  const openHamburgerStyles = isSidebarOpen ? styles.open : "";
  const menuIcon = isSidebarOpen ? openedImg : closedImg;

  return (
    <header className={styles.container} data-testid="navbar">
      <Button
        appearance="transparent"
        className={`${styles.hamburger} ${openHamburgerStyles}`}
        onClick={toggleSidebar}
      >
        <img src={menuIcon} alt="" />
      </Button>
      <div className={styles.title}>
        {activeRoute?.name} {isSidebarOpen}
      </div>
      <div className={styles.spacer} />
      <Tooltip
        triggerElement={
          <Button appearance="transparent">
            <img src={userImg} alt="" />
          </Button>
        }
        position="right-bottom"
        trigger="click"
      >
        <Menu history={history} />
      </Tooltip>
    </header>
  );
});

export default Navbar;
