import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { object, string } from "yup";
import {
  useSubmit,
  Button,
  Form,
  FormButtons,
  FormField,
  MessageField,
} from "@epcnetwork/core-ui-kit";

import { TOKEN_STORAGE_FIELD, REFRESH_TOKEN_STORAGE_FIELD } from "constants/auth.constants";
import { login } from "api";
import { setToken } from "store";
import { DEFAULT_PAGE } from "constants/routes.constants";

import logo from "assets/images/logo.svg";
import styles from "./login.module.css";

interface Values {
  email: string;
  password: string;
}

const initialValues: Values = {
  email: "",
  password: "",
};

const validationSchema = object().shape({
  email: string().email("Invalid Email").required("Email is required"),
  password: string().required("Password is required"),
});

const LoginPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { onSubmit, onSubmitSuccess, error } = useSubmit(login);

  onSubmitSuccess((payload) => {
    dispatch(setToken(payload.token));
    localStorage.setItem(TOKEN_STORAGE_FIELD, payload.token);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_FIELD, payload.refresh_token);
    history.push(DEFAULT_PAGE.path);
  });

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img className={styles.icon} src={logo} alt="" />
        <div className={styles.logoText}>Esp Hub</div>
      </div>
      <div className={styles.content}>
        <div className={styles.loginForm}>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <div className={styles.title}>Welcome to Esp Hub</div>
            <div className={styles.subtitle}>Please use your credential to Log In</div>
            <MessageField message={error?.message} align="left" />
            <FormField
              type="text"
              name="email"
              label="Email"
              inputSize="medium"
              placeholder="Fill your email"
            />
            <FormField
              type="password"
              name="password"
              label="Password"
              inputSize="medium"
              placeholder="Fill your password"
            />
            <div className={styles.forgotPassword}>Forgot password</div>
            <FormButtons align="right">
              <Button btnSize="medium" className={styles.btn} type="submit">
                Log in
              </Button>
            </FormButtons>
          </Form>
        </div>
        <div className={styles.info}>
          <div className={styles.option}>&#169;2021 Traffic</div>
        </div>
      </div>
      <div className={styles.sidebar}>
        <img src={""} alt="" />
      </div>
    </div>
  );
};

export default LoginPage;
