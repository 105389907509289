import React from "react";

import { ConnectorsKeysType } from "models";
import { DispatcherProps, DispatchType } from "./dispatcher.type";
import Bronto from "../connectors/bronto";
import Omnisend from "../connectors/omnisend";
import Remarkety from "../connectors/remarkety";
import Blueshift from "../connectors/blueshift";
import Iterable from "../connectors/iterable";
import Braze from "../connectors/braze";
import Maropost from "../connectors/maropost";
import ExactTarget from "../connectors/exact-target";

export const connectors: DispatchType = {
  [ConnectorsKeysType.blueshift]: () => <Blueshift />,
  [ConnectorsKeysType.braze]: () => <Braze />,
  [ConnectorsKeysType.bronto]: () => <Bronto />,
  [ConnectorsKeysType.exactTarget]: () => <ExactTarget />,
  [ConnectorsKeysType.iterable]: () => <Iterable />,
  [ConnectorsKeysType.maropost]: () => <Maropost />,
  [ConnectorsKeysType.omnisend]: () => <Omnisend />,
  [ConnectorsKeysType.remarkety]: () => <Remarkety />,
};

export const Dispatcher: React.FC<DispatcherProps> = ({ api_type }) => {
  return connectors[api_type]();
};
