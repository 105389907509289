// Pages
export const CONNECTIONS_LIST_PAGE = {
  path: "/connections",
  name: "Connections",
  exact: true,
  auth: true,
  showNavigation: true,
} as const;
export const CONNECTIONS_ADD_PAGE = {
  path: "/connections/add",
  name: "Add Vendor",
  exact: true,
  auth: true,
  showNavigation: true,
} as const;
export const CONNECTIONS_EDIT_PAGE = {
  path: "/connections/edit/:connectionId",
  name: "Add Vendor",
  exact: true,
  auth: true,
  showNavigation: true,
} as const;

// Default
export const DEFAULT_PAGE = { ...CONNECTIONS_LIST_PAGE, path: "/" };

// Auth
export const LOGIN_PAGE = {
  path: "/login",
  name: "Login",
  exact: true,
  auth: false,
  showNavigation: false,
};
export const LOGOUT_PAGE = {
  path: "/logout",
  name: "Logout",
  exact: true,
  auth: false,
  showNavigation: false,
};
export const NOT_FOUND_PAGE = {
  path: "*",
  name: "Not Found",
  exact: false,
  auth: false,
  showNavigation: false,
};
