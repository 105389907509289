import React from "react";
import {
  Container,
  Form,
  FormButtons,
  FormField,
  Button,
  MessageField,
} from "@epcnetwork/core-ui-kit";

import { useConnectionsFormHook } from "./connections-form.hook";
import { Dispatcher } from "./dispatcher/dispatcher";
import { apiTypesOptions, validationSchema } from "./connections-form.constants";

import styles from "./connections-form.module.css";

export const ConnectionsFormPage: React.FC = () => {
  const { formProps, connectionId, error, onSubmit, handleCancel } = useConnectionsFormHook();

  return (
    <Container>
      <div className={styles.container}>
        <div className={styles.title}>{connectionId ? "Edit" : "Create"} connection</div>
        <Form {...formProps} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ values, setFieldValue }) => {
            return (
              <>
                <div className={styles.row}>
                  <div>
                    <FormField
                      type="text"
                      name="name"
                      label="Name"
                      placeholder="Fill the name"
                      required
                    />
                    <FormField
                      name="api_type"
                      type="select"
                      label="Choose connector"
                      placeholder="Choose connector"
                      options={apiTypesOptions}
                      onFieldChange={() => setFieldValue("connector", {})}
                      disabled={!!connectionId}
                      disableClearing
                      required
                    />
                  </div>
                  <div>{values.api_type && <Dispatcher api_type={values.api_type} />}</div>
                </div>
                <MessageField message={error} />
                <FormButtons>
                  <Button type="button" appearance="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="submit">{connectionId ? "Save" : "Add"}</Button>
                </FormButtons>
              </>
            );
          }}
        </Form>
      </div>
    </Container>
  );
};

export default ConnectionsFormPage;
