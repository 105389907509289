import { useFetch, useFilters, usePagination, useQuery } from "@epcnetwork/core-ui-kit";
import { getConnections } from "api/connections/connections.api";
import { useShowContent } from "hooks";

const defaultOffset = 0;
const defaultLimit = 10;

export const useConnectionsListHook = () => {
  const { query } = useQuery();
  const { queryString } = useFilters({
    initialState: {
      offset: query.offset || defaultOffset,
      limit: query.limit || defaultLimit,
    },
  });
  const response = useFetch(getConnections.setQueryParams(queryString), {
    dependencies: [queryString],
  });
  const content = useShowContent(response);
  const pagination = usePagination(
    response.payload && {
      ...response.payload,
      limit: (query.limit as number) || defaultLimit,
      offset: (query.offset as number) || defaultOffset,
    },
  );

  return { ...content, ...response, pagination };
};
