import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { CONNECTIONS_LIST_PAGE } from "constants/routes.constants";
import { isActiveRoute } from "utils";

import styles from "../sidebar.module.css";

interface Props {
  src: string;
  text: string;
  to: string;
  closeSidebar: VoidFunction;
  exact?: boolean;
}

const Link: React.FC<Props> = ({ src, text, to, closeSidebar, exact }: Props) => {
  const location = useLocation();

  const getActiveStyle = () => {
    const isActive = isActiveRoute(to, location, exact);

    if (
      to === CONNECTIONS_LIST_PAGE.path &&
      (location.pathname === "" || location.pathname === "/")
    ) {
      return styles.activeLink;
    }

    return isActive ? styles.activeLink : "";
  };

  const activeStyle = getActiveStyle();

  return (
    <RouterLink to={to} className={`${styles.link} ${activeStyle}`} onClick={closeSidebar}>
      <img src={src} alt="" />
      {text}
    </RouterLink>
  );
};

export default Link;
