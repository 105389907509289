import React, { useState } from "react";
import { object, SchemaOf, string, array } from "yup";
import { Button, FormField, useDidMount } from "@epcnetwork/core-ui-kit";
import { useFormikContext } from "formik";

export const brontoSchema: SchemaOf<BrontoType> = object({
  api_key: string().required("This field is required"),
  list_ids: array().of(
    string().min(1, "This field is required").required("This field is required"),
  ),
});

export type BrontoType = {
  api_key: string;
  list_ids: string[];
};

const Bronto: React.FC = () => {
  const { setFieldValue } = useFormikContext();

  const [fieldsCount, setFieldsCount] = useState(0);

  const handleAddField = () => {
    setFieldsCount((prev) => prev + 1);
  };

  useDidMount(() => {
    setFieldValue("connector.list_ids", []);
  });

  return (
    <>
      <FormField type="text" name={`connector.api_key`} label="Api Key" placeholder="Api Key" />
      <FormField
        type="text"
        name={`connector.list_ids[0]`}
        label="List Ids (1)"
        placeholder="List Ids"
      />
      {Array.from(Array(fieldsCount), (_, i) => i + 1).map((index) => (
        <FormField
          key={index}
          type="text"
          name={`connector.list_ids[${index}]`}
          label={`List Ids(${index + 1})`}
          placeholder="List Ids"
        />
      ))}
      <Button type="button" appearance="primary-pale" onClick={handleAddField}>
        + Add list id
      </Button>
    </>
  );
};

export default Bronto;
