import { Location } from "history";
import { matchPath } from "react-router-dom";
import { ExtractRouteParams, Negatives } from "@epcnetwork/core-ui-kit";

import { routes } from "config/routes.config";
import { CONNECTIONS_LIST_PAGE } from "constants/routes.constants";

export const isActiveRoute = (routePath: string, location: Location, exact = false): boolean => {
  if (routePath === CONNECTIONS_LIST_PAGE.path && location.pathname === "/") {
    return true;
  }

  return Boolean(matchPath(location.pathname, { path: routePath, exact }));
};

export const getActiveRoute = (location: Location): typeof routes[0] => {
  const route = routes.find((route) => isActiveRoute(route.path, location, route.exact));

  if (route) {
    return route;
  }
  return routes[routes.length - 1];
};

export const getLinkPath = <T extends string>(
  path: T,
  ...paramsArray: ExtractRouteParams<T> extends Negatives ? [] : [ExtractRouteParams<T>]
): string => {
  let routePath: string = path;
  const params = paramsArray[0];
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      routePath = routePath.replaceAll(new RegExp(`:${key}`, "g"), String(value));
    });
  }

  return routePath;
};
