import { ConnectorsComponentsType } from "pages/connections/form/dispatcher/dispatcher.type";

export type ConnectionsModel = {
  id: string;
  name: string;
  status: string;
  api_type: ConnectorsKeysType;
  created_at: string;
} & ConnectorsDataType;

export enum ConnectorsKeysType {
  blueshift = "blueshift",
  braze = "braze",
  bronto = "bronto",
  exactTarget = "exact_target",
  iterable = "iterable",
  maropost = "maropost",
  omnisend = "omnisend",
  remarkety = "remarkety",
}
export type ConnectorsDataType = Partial<
  Record<`${ConnectorsKeysType}_data`, ConnectorsComponentsType>
>;
