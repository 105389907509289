import { apiMiddleware } from "../api.middleware";
import { UserModel } from "models";
import { LoginData, LoginResponse } from "./auth.interface";

export const login = apiMiddleware<LoginResponse, LoginData>()({
  method: "post",
  endpoint: `/auth/login`,
});

export const getMyProfile = apiMiddleware<UserModel>()({
  method: "get",
  endpoint: `/auth/user`,
});
