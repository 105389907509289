import React, { ReactElement } from "react";

import { NotFound } from "@epcnetwork/core-ui-kit";

import styles from "./not-found.module.css";

function NotFoundPage(): ReactElement {
  return (
    <div className={styles.container}>
      <NotFound size="big" title="Page Not found" subtitle="There is no such page" />
    </div>
  );
}

export default NotFoundPage;
