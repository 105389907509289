import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { store, RootState } from "store";
import { LOGIN_PAGE } from "constants/routes.constants";

type Props = unknown;

export const AuthRoute: React.FC<Props> = ({ ...rest }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const state = store.getState();
  const isValidRoute = state.auth.token && user;

  if (!isValidRoute) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_PAGE.path,
        }}
      />
    );
  }

  return <Route {...rest} />;
};
