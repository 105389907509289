import React from "react";

import Logo from "./components/logo";
import Menu from "./components/menu";

import styles from "./sidebar.module.css";

const Sidebar: React.FC = () => {
  return (
    <div className={styles.sidebar}>
      <Logo />
      <Menu />
    </div>
  );
};

export default Sidebar;
