import React from "react";
import { object, SchemaOf, string, number } from "yup";
import { FormField } from "@epcnetwork/core-ui-kit";

export const remarketySchema: SchemaOf<RemarketyType> = object({
  store_id: number().min(1, "This field is required").required("This field is required"),
  api_key: string().required("This field is required"),
});

export type RemarketyType = {
  store_id: number;
  api_key: string;
};
const Remarkety: React.FC = () => {
  return (
    <>
      <FormField
        type="number"
        name={`connector.store_id`}
        label="Store ID"
        placeholder="Store ID"
      />
      <FormField type="text" name={`connector.api_key`} label="Api Key" placeholder="Api Key" />
    </>
  );
};

export default Remarkety;
