import { ConnectorsKeysType } from "models";
import { ConnectorsValidationsMap } from "../dispatcher/dispatcher.type";

import { brontoSchema } from "../connectors/bronto";
import { omnisendSchema } from "../connectors/omnisend";
import { remarketySchema } from "../connectors/remarkety";
import { blueshiftSchema } from "../connectors/blueshift";
import { iterableSchema } from "../connectors/iterable";
import { brazeSchema } from "../connectors/braze";
import { exactTargetSchema } from "../connectors/exact-target";
import { maropostSchema } from "../connectors/maropost";

export const connectorsValidations: ConnectorsValidationsMap = {
  [ConnectorsKeysType.blueshift]: blueshiftSchema,
  [ConnectorsKeysType.braze]: brazeSchema,
  [ConnectorsKeysType.bronto]: brontoSchema,
  [ConnectorsKeysType.exactTarget]: exactTargetSchema,
  [ConnectorsKeysType.iterable]: iterableSchema,
  [ConnectorsKeysType.maropost]: maropostSchema,
  [ConnectorsKeysType.omnisend]: omnisendSchema,
  [ConnectorsKeysType.remarkety]: remarketySchema,
};
