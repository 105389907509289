import React from "react";
import { useDispatch } from "react-redux";

import Link from "./link";
import { CONNECTIONS_LIST_PAGE } from "constants/routes.constants";
import { setMenuOpen } from "store";

import styles from "../sidebar.module.css";
// import clientsIcon from "assets/images/menu-clients.svg";

type LinksType = {
  src: string;
  text: string;
  to: string;
  exact?: boolean;
  isMobile: boolean;
};

const Menu: React.FC = () => {
  const dispatch = useDispatch();

  const closeSidebar = () => {
    dispatch(setMenuOpen(false));
  };

  return (
    <div className={styles.menu}>
      {links.map((link) => (
        <Link {...link} key={link.text} closeSidebar={closeSidebar} />
      ))}
    </div>
  );
};

export default Menu;

const links: LinksType[] = [
  {
    src: "", // clientsIcon,
    text: CONNECTIONS_LIST_PAGE.name,
    to: CONNECTIONS_LIST_PAGE.path,
    isMobile: true,
  },
];
