import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { NotAuthorized } from "@epcnetwork/core-ui-kit";

import { RootState, setMenuOpen } from "store";
import { getActiveRoute } from "utils";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import { RouteConfig } from "config/routes.config";

import styles from "./navigation.module.css";

interface Props {
  component: React.FC<unknown>;
  showNavigation: boolean;
  isAuthorized?: boolean;
}

type NavigationContextValues = {
  activeRoute: RouteConfig;
};

export const NavigationContext = React.createContext<NavigationContextValues | null>(null);

export const Navigation = memo(
  ({ component, showNavigation, isAuthorized = true, ...rest }: Props) => {
    const Component = component;
    const location = useLocation();
    const currentRoute = useMemo(() => getActiveRoute(location), [location]);

    const dispatch = useDispatch();

    const context: NavigationContextValues = {
      activeRoute: currentRoute,
    };

    const { menuOpen } = useSelector((state: RootState) => state.ui);

    const toggleSidebar = () => {
      if (menuOpen) {
        dispatch(setMenuOpen(false));
      } else {
        dispatch(setMenuOpen(true));
      }
    };

    if (!isAuthorized) {
      return (
        <NotAuthorized title="Access Denied" subtitle="You're not authorized to view this page" />
      );
    }

    if (!showNavigation) {
      return <Component {...rest} />;
    }

    const activeSidebarStyle = menuOpen ? styles.open : "";

    return (
      <NavigationContext.Provider value={context}>
        <div className={styles.container} data-testid="app">
          <div className={`${activeSidebarStyle} ${styles.sidebar}`} data-testid="sidebar">
            <Sidebar />
          </div>
          <div className={styles.content} data-testid="content">
            <Navbar toggleSidebar={toggleSidebar} isSidebarOpen={menuOpen} />
            <Component {...rest} />
          </div>
        </div>
      </NavigationContext.Provider>
    );
  },
);

export default Navigation;
