import { ConnectorsKeysType } from "models";
import { mixed, string, object, SchemaOf } from "yup";
import { connectorsValidations } from "./validations/validations";

import { InitialValues } from "./connections-form.types";
import { connectors } from "./dispatcher/dispatcher";

export const validationSchema: SchemaOf<Omit<InitialValues, "connector">> = object().shape({
  name: string().min(3, "Field must have minimum 3 characters").required("This field is required"),
  api_type: mixed().required("This field is required"),
  connector: mixed()
    .when("api_type", (api_type: ConnectorsKeysType) => connectorsValidations[api_type])
    .required("This field is required"),
});

export const initialValues: InitialValues = {
  name: "",
  api_type: ConnectorsKeysType.bronto,
  connector: { api_key: "" },
};

export const apiTypesOptions = Object.keys(connectors).map((key) => ({ value: key, label: key }));
