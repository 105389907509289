import React from "react";
import ReactDOM from "react-dom";

import { Providers } from "components";
import App from "./app";
import reportWebVitals from "./reportWebVitals";

import "assets/styles/index.css";
import "assets/styles/theme.css";
import "@epcnetwork/core-ui-kit/dist/assets/styles/index.css";

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <div className="theme">
        <App />
      </div>
    </Providers>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
