import React from "react";
import { object, SchemaOf, string } from "yup";
import { FormField } from "@epcnetwork/core-ui-kit";

export const brazeSchema: SchemaOf<BrazeType> = object({
  api_key: string().required("This field is required"),
});

export type BrazeType = {
  api_key: string;
};

const Braze: React.FC = () => {
  return (
    <>
      <FormField type="text" name={`connector.api_key`} label="Api Key" placeholder="Api Key" />
    </>
  );
};

export default Braze;
